import './media-selector.scss';

import { loadYoutubeApi } from '../../utilities/js/helper';

class MediaSelector {
    constructor (element) {
        this.$media = element;
        this.mediaType = this.$media.getAttribute('data-media');
        this.$video = null;
    }

    initialize () {
        if (this.mediaType === 'youtube') {
            this.$video = this.$media.querySelector('[data-youtube]');

            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }

        if (this.mediaType === 'video') {
            this.$video = this.$media.querySelector('video');
            this.initHTML5Player();
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        if (this.$video) {
            const youtubeId = this.$video.getAttribute('data-youtube');
            const $playButton = this.$media.querySelector('[data-media="play"]');

            if (youtubeId !== '') {
                const videoPlayer = new YoutubePlayer(this.$video, {
                    silent: !!this.$video.getAttribute('data-muted'),
                    autoplay: !!this.$video.getAttribute('data-autoplay'),
                    fullscreen: !!this.$video.getAttribute('data-fullscreen'),
                    loop: !!this.$video.getAttribute('data-loop'),
                    root: this.$media,
                    id: youtubeId,
                    observe: true,
                    onCall: () => {
                        this.$media.classList.add('is--shown');
                    },
                    onStart: (videoId) => {
                        this.$media.classList.add('is--shown');
                    },
                    onEnd: (videoId) => {
                        if (videoPlayer.player.video.isMuted() === false) {
                            videoPlayer.destroy();
                            this.$media.classList.remove('is--shown');
                        }
                    },
                    onPause: (videoId, progress) => {
                        console.log('video pause');
                    }
                });

                $playButton.addEventListener('keydown', (e) => {
                    if (e.code === 'Space') {
                        e.preventDefault();
                        $playButton.click();
                    }
                });
            }
        }
    }

    initHTML5Player () {
        const autoplay = (this.$video.getAttribute('autoplay') === '');
        const loop = (this.$video.getAttribute('loop') === '');
        const controls = (this.$video.getAttribute('controls') === '');
        const $playButton = this.$media.querySelector('[data-media="play"]');
        const $pauseButton = this.$media.querySelector('[data-media="pause"]');

        if (autoplay) {
            this.$media.classList.add('is--playing');
            this.$video.load();
        }

        if (!loop) {
            this.$video.addEventListener('ended', () => {
                this.$media.classList.remove('is--playing');

                if (!controls) {
                    showPlayButton();
                }
            });
        }

        if ($playButton) {
            $playButton.addEventListener('click', () => {
                this.$media.classList.add('is--playing');
                showPauseButton();
                this.$video.play();
            });

            $playButton.addEventListener('keydown', (e) => {
                if (e.code === 'Space') {
                    e.preventDefault();
                    this.$media.classList.add('is--playing');
                    showPauseButton();
                    this.$video.play();
                }
            });
        } else {
            this.$video.addEventListener('playing', () => {
                this.$media.classList.add('is--playing');
                showPauseButton();
            });
        }

        if ($pauseButton) {
            $pauseButton.addEventListener('click', () => {
                this.$media.classList.remove('is--playing');
                showPlayButton();
                this.$video.pause();
            });

            $pauseButton.addEventListener('keydown', (e) => {
                if (e.code === 'Space') {
                    e.preventDefault();
                    this.$media.classList.remove('is--playing');
                    showPlayButton();
                    this.$video.pause();
                }
            });
        } else {
            this.$video.addEventListener('pause', () => {
                this.$media.classList.remove('is--playing');
                showPlayButton();
            });
        }

        const showPauseButton = () => {
            $playButton.setAttribute('aria-hidden', true);
            $playButton.removeAttribute('tabindex');
            $pauseButton.removeAttribute('aria-hidden');
            $pauseButton.setAttribute('tabindex', 0);
        };

        const showPlayButton = () => {
            $pauseButton.setAttribute('aria-hidden', true);
            $pauseButton.removeAttribute('tabindex');
            $playButton.removeAttribute('aria-hidden');
            $playButton.setAttribute('tabindex', 0);
        };
    }
}

export { MediaSelector };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $mediasYoutube = $context.querySelectorAll('[data-media="youtube"]');
        const $mediasVideo = $context.querySelectorAll('[data-media="video"]');

        if ($mediasYoutube.length > 0) {
            const waitForConsent = setInterval(() => {
                if (typeof OneTrust !== 'undefined') { // eslint-disable-line
                    if (OnetrustActiveGroups.indexOf('C0008') != -1) { // eslint-disable-line
                        if (typeof window.YT === 'undefined') {
                            loadYoutubeApi();

                            window.onYouTubeIframeAPIReady = () => {
                                $mediasYoutube.forEach(($media) => {
                                    const media = new MediaSelector($media);
                                    const $placeholder = $media.querySelector('.media__restriction');

                                    if ($placeholder) {
                                        $placeholder.remove();
                                    }

                                    media.initialize();
                                });
                                clearInterval(waitForConsent);
                            };
                        } else {
                            $mediasYoutube.forEach(($media) => {
                                const media = new MediaSelector($media);
                                const $placeholder = $media.querySelector('.media__restriction');

                                if ($placeholder) {
                                    $placeholder.remove();
                                }
                                media.initialize();
                            });
                            clearInterval(waitForConsent);
                        }
                    } else {
                        $mediasYoutube.forEach(($media) => {
                            const $placeholder = $media.querySelector('.media__restriction');
                            const $button = $media.querySelector('[data-restriction="remove"]');

                            if ($button) {
                                $button.addEventListener('click', () => {
                                    if (typeof OneTrust.UpdateConsent !== 'undefined') { // eslint-disable-line
                                        OneTrust.UpdateConsent("Category","C0008:1"); // eslint-disable-line
                                        setTimeout(() => {
                                            location.reload();
                                        }, 500);
                                    } else {
                                        OneTrust.ToggleInfoDisplay(); // eslint-disable-line
                                    }
                                });
                            }

                            if ($placeholder) {
                                $placeholder.classList.add('is--shown');
                                $placeholder.closest('[data-media="youtube"]')?.classList.add('has--restriction');
                            }
                        });
                        clearInterval(waitForConsent);
                    }
                }
            }, 300);
        }

        if ($mediasVideo.length > 0) {
            $mediasVideo.forEach((media) => {
                const $media = new MediaSelector(media);
                $media.initialize();
            });
        }
    }
});
